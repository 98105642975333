var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-contents"},[_c('FiltersPanel',{staticClass:"sp-xxs full-width",attrs:{"filters":_vm.dictionary,"form-filters":_vm.filter,"drawerComponentName":"RepairFilterForm","modalComponentName":"RepairTableSettingModal","isSmallModal":true,"isAllChips":false},model:{value:(_vm.activeStatus),callback:function ($$v) {_vm.activeStatus=$$v},expression:"activeStatus"}},[_c('VBtn',{staticClass:"sp-r-xxs",class:[{ 'btn--square': _vm.$vuetify.breakpoint.xsOnly },'btn--secondary'],on:{"click":function($event){return _vm.$openModal('RepairDownloadModal', 'small')}}},[_vm._v(_vm._s(!_vm.$vuetify.breakpoint.xsOnly ? 'Excel' : '')),_c('VIcon',{attrs:{"color":"red","center":""}},[_vm._v("mdi-tray-arrow-down")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData && _vm.tableData.length),expression:"tableData && tableData.length"}],staticClass:"table full-width"},[_c('VDataTable',{ref:"dataTable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.filter.page,"items-per-page":_vm.pagination.perPage,"calculate-widths":"","header-props":{ sortIcon: 'mdi-chevron-down' },"hide-default-footer":"","mobile-breakpoint":"0","server-items-length":_vm.pagination.total},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"update:sort-by":_vm.changeSortBy,"update:sort-desc":_vm.changeSortDesc},scopedSlots:_vm._u([{key:"item.vin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"content_copy"},[_c('div',{on:{"click":function($event){return _vm.goToCarInfo(item)}}},[_vm._v(_vm._s(item.vin))]),_c('VIcon',{attrs:{"color":"red","center":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyText(item.vin)}}},[_vm._v("mdi-content-copy")])],1)]}},{key:"item.stateNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inline-additional"},[_vm._v(_vm._s(item.stateNumber.slice(0, 6)))]),_c('div',{staticClass:"inline-additional"},[_vm._v(_vm._s(item.stateNumber.slice(6)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('VChip',{staticClass:"mr-4",attrs:{"color":_vm.getColor(item.status),"outlined":"","small":""}},[_c('Paragraph',{attrs:{"size":"body"}},[_vm._v(_vm._s(item.status.toLowerCase()))]),(item.status === 'Новый')?_c('VMenu',{attrs:{"offset-y":"","right":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g({staticClass:"ml-2",attrs:{"color":"blue","size":"small"}},on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('VList',[_c('VListItem',{attrs:{"link":""},on:{"click":function($event){return _vm.toApprove(item)}}},[_c('VListItemTitle',[_vm._v("Согласовать")])],1),_c('VListItem',{attrs:{"link":""},on:{"click":function($event){return _vm.toContest(item)}}},[_c('VListItemTitle',[_vm._v("Оспорить")])],1)],1)],1):_vm._e()],1):_vm._e()]}},{key:"item.fileExcel",fn:function(ref){
var item = ref.item;
return [(item.fileExcel && item.fileExcel.length)?_c('a',{staticClass:"mr-2",attrs:{"download":"","target":"_blank","href":item.fileExcel},on:{"click":function($event){$event.stopPropagation();}}},[_c('VIcon',{attrs:{"color":"dark-blue","center":""}},[_vm._v("mdi-file-excel-outline")])],1):_vm._e(),(item.filePdf && item.filePdf.length)?_c('a',{attrs:{"download":"","target":"_blank","href":item.filePdf},on:{"click":function($event){$event.stopPropagation();}}},[_c('VIcon',{attrs:{"color":"dark-blue","center":""}},[_vm._v("mdi-file-pdf-box")])],1):_vm._e()]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"pagination":props.pagination,"filter":_vm.filter,"mod":"table"},on:{"update:filter":function($event){_vm.filter=$event}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }