





import RepairTable from '@/components/tables/RepairTable.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    RepairTable,
  },
})
export default class RepairTab extends Vue {}
